<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <b-container>
      <b-row class="table-bottom-wrapper mb-2 mx-0">
        <b-col :cols="12" class="px-0">
          <b-button
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="onAdd()"
          >
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Create New Event
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row v-if="eventToShow.length === 0">
        <b-col class="text-center">
          <span class="spinner spinner-white"></span>
        </b-col>
      </b-row>
      <b-row align-v="center" v-if="eventToShow.length !== 0">
        <b-col lg="3" md="6" sm="12" v-for="(event, index) in eventToShow" :key="index">
          <b-row>
            <b-col sm="12" lg="12" md="12">
              <div :class="{ tile: !isDarkMode, 'tile-dark dark-div rounded box-shadow2': isDarkMode }">
                <div
                  :class="{ 'tile-heading': !isDarkMode, 'tile-heading-dark text-light': isDarkMode }"
                  @click="onEdit(event)"
                  >{{ event.name }}</div
                ><br />
                <div>
                  <b-button
                    size="sm"
                    :variant="isDarkMode ? 'outline-success' : 'success'"
                    @click="onEdit(event)"
                    v-b-tooltip.hover
                    title="Edit this Event"
                  >
                    <font-awesome-icon :icon="['fas', 'edit']" class="archive" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'ActiveEvent',
  metaInfo() {
    return {
      title: 'Active Events',
      meta: [
        {
          name: 'description',
          content: 'View Events'
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listEvents({
        query: {
          ...this.$route.query,
          page_size: 999
        }
      });
    } else {
      this.listEvents({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {},
  data() {
    return {
      title: 'Active Events of Disha',
      eventToShow: []
    };
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('events', ['loading', 'baseUrl', 'events', 'pagination'])
  },
  watch: {
    events(newValue) {
      this.eventToShow = newValue.filter(event => moment(event.endDate).diff(moment()) > 0);
    }
  },
  methods: {
    ...mapActions('events', ['listEvents']),

    onAdd() {
      router.push('/event-plan/new');
    },

    onEdit(row) {
      router.push(`/event-plan/${row.id}`);
    }
  }
};
</script>

<style scoped>
.tile {
  height: auto;
  margin: 10px;
  padding: 10px;
  display: block;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
}
.tile-dark {
  height: auto;
  margin: 10px;
  padding: 10px;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tile-heading {
  color: rgb(71, 167, 69);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tile-heading-dark {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tile-heading:hover {
  cursor: pointer;
  transition: 0.5s;
  color: rgb(37, 105, 36);
  white-space: normal;
}
.tile-heading-dark:hover {
  cursor: pointer;
  transition: 0.5s;
  white-space: normal;
}
.archive {
  position: center;
  align-self: center;
  /* margin: 5px; */
}

.form_heading {
  display: flex;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
}
</style>
