<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>
<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <h5> {{ eventName ? `Event Name : ${eventName}` : '' }}</h5>
        <h5> {{ storeName ? `Store Name : ${storeName}` : '' }}</h5>
        <h5> {{ storeId ? `Store ID : ${storeId}` : '' }}</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button class="box-shadow2" @click="downloadImages" :variant="isDarkMode ? 'outline-success' : 'success'"
          >Download Images</b-button
        >
      </b-col>
    </b-row>

    <div v-viewer>
      <!-- time stamp images  -->
      <b-row>
        <b-col>
          <div v-if="this.images != ''">
            <div v-bind:key="item" v-for="item in Object.keys(images)">
              <h5 class="titleDate" v-if="item !== null">{{ item }}</h5>
              <div>
                <input :checked="allSelected" @click="selectAll" class="allimage" type="checkbox" id="scales" />
                <label for="scales">select all images</label>
              </div>
              <div>
                <div>
                  <template v-for="image in images[item].map(i => i.url)">
                    <div :key="image" class="imageview">
                      <input
                        class="checkbox2"
                        type="checkbox"
                        :checked="selectedImages.includes(image)"
                        @click="select(image)"
                      />
                      <label for="mycheckbox"> <img :src="image" class="image box-shadow2" /></label>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Non-time stamp images -->
      <div v-if="this.OldImages != ''">
        <h5 class="titleDate">Old Images</h5>
        <div>
          <input :checked="allSelectedOld" @click="selectAllOld" class="allimage" type="checkbox" id="scales" />
          <label for="scales">select all images</label>
        </div>
        <template v-for="image in OldImages">
          <div :key="image" class="imageview">
            <input
              class="checkbox2"
              type="checkbox"
              :checked="selectedImages.includes(image)"
              @click="selectOld(image)"
            />
            <label for="mycheckbox"> <img :src="image" class="image" /></label>
          </div>
        </template>
      </div>
    </div>
    <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
    <div class="header"> <h4>Comments</h4></div>
    <comment @remove="onRemoveEventComment" :tag="tag" :eventId="$route.params.id"> </comment>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Comment from '../Comment/Comment.vue';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import moment from 'moment';

Vue.use(VueViewer);

export default {
  name: 'EventView',
  components: {
    Comment
  },
  data() {
    return {
      selectedImages: [],
      allSelected: false,
      allSelectedOld: false,
      images: [],
      OldImages: [],
      eventName: '',
      storeName: '',
      storeId: '',
      tag: null
    };
  },
  async mounted() {
    this.getOne({
      id: this.$route.params.id,
      query: {
        id: this.$route.params.id
      }
    });
  },
  computed: {
    title() {
      return 'Event';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('photoSharing', ['eventphoto', 'loading']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('photoSharing', ['getOne', 'downloadAll']),
    onRemoveEventComment() {
      this.tag = null;
    },
    downloadImages() {
      if (this.selectedImages.length > 20) {
        Vue.swal({
          title: 'Note',
          text: 'The selected images are more than 20.  So,  the images will be downloaded in multiple zip files.',
          icon: 'success',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        // divide the array into chunks of 20
        let chunks = [];
        let i,
          j,
          temparray,
          chunk = 20;
        for (i = 0, j = this.selectedImages.length; i < j; i += chunk) {
          temparray = this.selectedImages.slice(i, i + chunk);
          chunks.push(temparray);
        }
        chunks.forEach(chunk => {
          this.downloadAll({
            images: chunk
          });
        });
      } else {
        this.downloadAll({
          images: this.selectedImages
        });
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedImages = [];
        this.allSelected = false;
      } else {
        this.selectedImages = Object.keys(this.images).reduce((acc, item) => {
          acc = acc.concat(this.images[item].map(i => i.url));
          return acc;
        }, []);
        this.allSelected = true;
      }
    },
    selectAllOld() {
      if (this.allSelectedOld) {
        this.selectedImages = [];
        this.allSelectedOld = false;
      } else {
        this.selectedImages = this.OldImages;
        this.allSelectedOld = true;
      }
    },
    select(image) {
      this.allSelected = false;
      if (this.selectedImages.includes(image)) {
        this.selectedImages = this.selectedImages.filter(i => i !== image);
      } else {
        this.selectedImages = [...this.selectedImages, image];
      }
    },
    selectOld(image) {
      this.allSelectedOld = false;
      if (this.selectedImages.includes(image)) {
        this.selectedImages = this.selectedImages.filter(i => i !== image);
      } else {
        this.selectedImages = [...this.selectedImages, image];
      }
    }
  },
  watch: {
    eventphoto(newValue) {
      if (newValue !== undefined || newValue !== null) {
        const dataWithUrl = newValue?.image?.filter(i => i.hasOwnProperty('url'));
        this.ObjectImages = _.chain(dataWithUrl)
          .groupBy(i => moment(i.createdAt).format('Do MMMM YYYY'))
          .value();

        const Arrayimages = Object.keys(this.ObjectImages);
        this.images = Arrayimages.reverse().reduce((acc, item) => {
          acc[item] = this.ObjectImages[item];
          return acc;
        }, {});

        const dataWithoutUrl = newValue?.image?.filter(i => !i.hasOwnProperty('url'));
        this.OldImages = dataWithoutUrl;
        this.eventName = newValue?.eventName;
        this.storeName = newValue?.storeName;
        this.storeId = newValue?.storeid;
      }
    }
  }
};
</script>

<style>
.image {
  height: 200px;
  width: 200px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.title {
  text-align-last: center;
}
.titleDate {
  text-align-last: center;
  margin-top: 2%;
}
.imageview {
  display: inline-flex;
}
.allimage {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.checkbox2 {
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.header {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}

.nameCtr {
  text-align: center;
  flex-grow: 1;
}
.child {
  display: inline-block;
  text-align: left;
}
@media only screen and (max-width: 450px) {
  .header {
    flex-direction: column;
  }
}
</style>
