<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <event-box
      :columns="columns"
      :rows="eventphotos"
      :baseUrl="baseUrl"
      :loading="loading"
      :showToolbar="true"
      :showPagination="true"
      :showReportDownload="true"
      :showList="ifAdmin()"
      :showFilter="true"
      :filterOptions="filterOptions"
      :showDownload="true"
      :pagination="pagination"
      :searchItems="searchItems"
      emptyText="No Events found. Please add new Event."
      :downloadReportText="'Download Report'"
      listText="Events Planning List"
      downloadText="Download MIS"
      @delete="onDelete"
      @open="onView"
      @list="viewList"
      @download="downloadMIS"
      @download-images="downloadImages"
      @downloadreport="onDownloadReport"
    >
    </event-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import axios from 'axios';
import configService from '@/services/configService';
import EventBox from '../../components/EventBox.vue';

// import User from '../../model/user';

export default {
  name: 'Feedback',
  components: { EventBox },
  metaInfo() {
    return {
      title: 'Events',
      meta: [
        {
          name: 'description',
          content: `Manage Events`
        }
      ]
    };
  },
  mounted() {
    this.listEvents({ router });
    if (_.isEmpty(this.$route.query)) {
      this.listPhoto({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listPhoto({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.query.filter) {
      this.listPhoto({ query: { ...to.query, filter: from.query.filter } });
    } else {
      this.listPhoto({ query: to.query });
    }
    next();
  },
  data() {
    return {
      title: 'Events',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Event Name',
          class: { 'text-center': true },
          textKey: 'eventName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '14%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'Updated At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '17%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            open: true,
            edit: false,
            delete: true,
            images: true
          }
        }
      ],
      searchItems: ['Store Id', 'Event Name'],
      filterOptions: []
    };
  },
  computed: {
    ...mapState('photoSharing', ['loading', 'baseUrl', 'eventphotos', 'pagination', 'report', 'appliedFilter']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('events', ['misCountList', 'events'])
  },
  methods: {
    ...mapActions('photoSharing', [
      'listPhoto',
      'deleteOne',
      'getone',
      'downloadAll',
      'downloadEventReport',
      'setFilter'
    ]),
    ...mapActions('events', ['downloadMis', 'listEvents']),
    onView({ row }) {
      router.push(`/photo-sharing-event/${row.id}`); //eslint-disable-line
    },
    viewList() {
      router.push(`/eventplanlist`);
    },
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN');

      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    downloadMIS({ download = true }) {
      this.downloadMis({ query: this.$route.query, download });
    },
    onDownloadReport({}) {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'page')) {
        this.downloadEventReport({ query: { ...this.$route.query, page_size: 9999, page: 1 } });
      } else {
        this.downloadEventReport({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1
          }
        });
      }
    },
    downloadImages({ row }) {
      let images = [];
      if (row.image) {
        // const last3Days = moment().subtract(3, 'days').format('YYYY-MM-DD');
        // images = row.image.filter(image => moment(image.createdAt).format('YYYY-MM-DD') >= last3Days);
        images = row.image;
        images = images.map(image => image.url);
      }

      if (images.length === 0) {
        Vue.swal({
          title: 'No images found',
          text: 'Sorry, no images found for this event',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      }

      if (images.length > 20) {
        Vue.swal({
          title: 'Note',
          text: 'The selected images are more than 20.  So,  the images will be downloaded in multiple zip files.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

        const chunks = [];
        let i;
        let j;
        let temparray;
        const chunk = 20;
        for (i = 0, j = images.length; i < j; i += chunk) {
          temparray = images.slice(i, i + chunk);
          chunks.push(temparray);
        }
        chunks.forEach((chunk, index) => {
          axios
            .post(
              `${configService.get('apiUrl')}/photo-sharing-event/download-photos`,
              { images: chunk },
              { responseType: 'blob' }
            )
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${row.storeid}.zip`);
              document.body.appendChild(link);
              link.click();
            })
            .catch(error => {
              throw error;
            });
        });
      } else {
        axios
          .post(
            `${configService.get('apiUrl')}/photo-sharing-event/download-photos`,
            { images },
            { responseType: 'blob' }
          )
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${row.storeid}.zip`);
            document.body.appendChild(link);
            link.click();
          })
          .catch(error => {
            throw error;
          });
      }
      // axios
      //   .post(
      //     `${configService.get('apiUrl')}/photo-sharing-event/download-photos`,
      //     { images },
      //     { responseType: 'blob' }
      //   )
      //   .then(response => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', `${row.storeid}.zip`);
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch(e => {
      //     throw e;
      //   });
    },

    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  },
  watch: {
    events(value) {
      this.filterOptions = [{ value: null, text: 'Select  filter' }];
      const unique = [...new Set(value.map(o => o.name))];
      this.filter = unique.map(i => {
        return {
          value: `EventName-${i}`,
          text: i
        };
      });
      this.filterOptions = [...this.filterOptions, ...this.filter];
    },
    misCountList(newValue) {
      if (newValue.length) {
        const keys = newValue.map(item => item.eventName);
        const uniqueKeys = [...new Set(keys)];
        const storeids = newValue.map(item => item.storeid);
        const uniqueStoreids = [...new Set(storeids)];
        let csv = '';
        csv += `,,Report From ${moment(this.$route.query.startdate).format('DD-MM-YYYY')}   To   ${moment(
          this.$route.query.enddate
        ).format('DD-MM-YYYY')} \n`;
        csv += `,,,,\n`;
        csv += `Store Id,${uniqueKeys.map(i => `"${i}"`).join(',')}\n`;
        uniqueStoreids.forEach(storeid => {
          csv += `${storeid},`;
          uniqueKeys.forEach(key => {
            const item = newValue.find(i => i.eventName === key && i.storeid === storeid);
            if (item) {
              const dates = item.image;
              const datesArray = dates.map(e => {
                const d = new Date(e);
                return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
              });
              const uniqueDates = [...new Set(datesArray)];
              csv += `${uniqueDates.length},`;
            } else {
              csv += `0,`;
            }
          });
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    },
    report(newValue) {
      if (newValue) {
        let csv = '';
        csv += `,,,Last 45 Days Event Report\n`;
        csv += `,,,Report From  ${moment().subtract(45, 'days').format('DD-MM-YYYY')}  to   ${moment()
          .subtract(1, 'days')
          .format('DD-MM-YYYY')}\n`;
        csv += `,,,\n`;
        csv += `Event Name, Event Startdate,Event Enddate,Store Id, Photo Shared,Link\n`;
        const arr = newValue.map(i => ({
          eventName: i.eventName,
          eventStart:
            i.eventStart.map(i => i).length === 0 ? '-' : i.eventStart.map(item => moment(item).format('DD-MM-YYYY')),
          eventEnd:
            i.eventEnd.map(i => i).length === 0 ? '-' : i.eventEnd.map(item => moment(item).format('DD-MM-YYYY')),
          storeid: i.storeid,
          photoShared: i.photoShared,
          link: `https://spencers-visit.attabot.in/photo-sharing-event/${i.link}`
        }));
        arr.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Events Report.csv';
        anchor.click();
      }
    }
  }
};
</script>
